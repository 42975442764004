<template>
  <div>
    <pcHeaders></pcHeaders>

    <div style="min-height: 80vh;">
      <div style="text-align: center;font-size: 18px;width: 1200px;margin: 30px auto; ">
        {{ messageInfo.picTitle }}
      </div>

      <div style="margin: 0 auto;width: 1200px;" v-html="messageInfo.picDesc">

      </div>
      
    </div>

    <pcBottom></pcBottom>
  </div>
</template>
<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import pcHeaders from './component/head.vue';
import pcBottom from './component/bottom.vue';
export default {
  // import引入的组件需要注入到对象中才能使用
  components: {
    pcHeaders,
    pcBottom,
  
  },
  data() {
    // 这里存放数据
    return {
     messageInfo:{}
    };
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
   
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.messageInfo = JSON.parse(this.$route.query.data)
    console.log(this.messageInfo);
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, // 生命周期 - 创建之前
  beforeMount() {} // 生命周期 - 挂载之前
};
</script>
<style lang="less">
/* //@import url(); 引入公共css类 */

</style>